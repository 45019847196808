<template>
  <div>
    <el-row type="flex" style="padding-top: 34px">
      <el-col :span="12" :offset="6">
        <page-header :title="headerTitle" />
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="12" :offset="6">
        <el-form-item prop="name" :label="__('Name')">
          <javascript-identifier-input v-model="javascriptFn.name" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="12" :offset="6">
        <el-form-item prop="function_return_type" :label="__('Return Type')">
          <javascript-type-selector
            v-model="javascriptFn.function_return_type"
            @change="onJavascriptFnTypeChange"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="12" :offset="6">
        <el-form-item prop="description" :label="__('Description')">
          <el-input id="jsFuncDescField" v-model="javascriptFn.description" />
          <span>
            {{
              __("This description will help explain what the function does.")
            }}
          </span>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="12" :offset="6" style="margin-bottom: 34px;">
        <function-argument-table
          v-model="argumentsCopy"
          argLimit="ARG_LIMIT"
          @change="functionArgumentChangeHandler"
        />
      </el-col>
    </el-row>

    <el-row type="flex" v-if="hasFunctionArgumentError">
      <el-col :span="12" :offset="6" style="margin-bottom: 34px;">
        <el-alert
          :title="__('Function Argument Error')"
          type="warning"
          :closable="false"
          show-icon
        >
          <div id="functionArgumentErrorDesc">
            {{ functionArgumentErrorDesc }}
            <ul>
              <li v-for="(error, idx) in functionArgumentErrors" :key="idx">
                {{ formatArgumentError(error) }}
              </li>
            </ul>
          </div>
        </el-alert>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="12" :offset="6">
        <el-form-item prop="javascript_library" :label="__('Library')">
          <el-select
            v-model="javascriptFn.libraries"
            multiple
            filterable
            default-first-option
            :placeholder="__('Select')"
            @change="updateSelectedLibraries"
          >
            <el-option
              v-for="library in SupportedLibraries"
              :key="library.value"
              :label="beautifyLibraryLabel(library)"
              :value="library.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="12" :offset="6">
        <el-form-item :label="__('Documentation')" v-if="hasLibraries">
          <ul>
            <li v-for="(library, index) in selectedLibraryLinks" :key="index">
              <el-link :href="library.docLink" target="blank">
                {{ beautifyLibraryLabel(library) }} documentation<i
                  class="el-icon-copy-document el-icon--right"
                ></i>
              </el-link>
            </li>
          </ul>
        </el-form-item>

        <el-alert type="error" v-if="noCodeCompletionsReturns">
          {{ __("No code completions were returned") }}
        </el-alert>

        <el-row
          class="form-editor-container"
          v-loading="generatingCodeCompletions"
        >
          <div
            class="ace_editor ace_hidpi ace-solarized-dark ace_dark ace_editor_dummy-text"
          >
            <div
              class="ace_line flex"
              style="height: 20px; top: 0; justify-content: space-between; align-items: center"
            >
              <div>
                <span class="ace_storage ace_type">function </span>
                <span class="ace_entity ace_name ace_function">{{
                  javascriptFn.name
                }}</span>
                <span class="ace_paren ace_lparen"> (</span>
                <span v-for="(val, index) of getFnArgsName" v-bind:key="val">
                  <span class="ace_variable ace_parameter">{{ val }}</span>
                  <span
                    class="ace_punctuation ace_operator"
                    v-if="index !== getFnArgsName.length - 1"
                    >,
                  </span>
                </span>
                <span class="ace_paren ace_rparen">) </span>
                <span class="ace_paren ace_lparen">{</span>
              </div>
              <el-tooltip
                v-if="generateCodeEnabled"
                class="item"
                effect="dark"
                :content="__('Generate Code')"
                placement="top-start"
              >
                <span
                  style="cursor: pointer; display: inline-block; width: 20px; height: 20px"
                  id="generateCode"
                  @click="generateCodeCompletion"
                >
                  <WandIcon />
                </span>
              </el-tooltip>
            </div>
          </div>
          <ace-editor
            v-model="javascriptFunctionContent"
            @init="editorInit"
            lang="javascript"
            theme="solarized_dark"
            width="100%"
            height="200px"
          ></ace-editor>
          <div
            class="ace_editor ace_hidpi ace-solarized-dark ace_dark ace_editor_dummy-text"
          >
            <div class="ace_line" style="height: 16px; top: 0;">
              <span class="ace_paren ace_lparen">}</span>
            </div>
          </div>
        </el-row>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="12" :offset="6">
        <el-row
          v-loading="requestingHttpResponse"
          class="form-test-container"
          :gutter="5"
          type="flex"
          justify="flex-end"
        >
          <el-col
            style="display: flex; justify-content: flex-start; align-items: center"
          >
            <el-alert
              v-if="isMaxCodeLimitExceeded"
              :title="
                __(
                  'Function Content has exceeded max character limit of :maxCodeLimitCharacters',
                  { maxCodeLimitCharacters: this.maxCodeLimitCharacters }
                )
              "
              :description="
                __('Please reduce the size of your content before saving')
              "
              type="error"
              show-icon
              :closable="false"
            ></el-alert>
            <el-dialog
              :title="__('Evaluate Javascript Function Result')"
              :visible.sync="dialogVisible"
              width="50%"
              append-to-body
            >
              <div v-if="evaluateJavascriptFunctionHttpResponse">
                <el-alert
                  :title="getResponseTitle"
                  :type="getResponseStatus"
                  show-icon
                  :closable="false"
                >
                </el-alert>
                <vue-json-pretty
                  v-if="isOutputJson"
                  style="margin: 10px; font-size: 0.8em"
                  :data="getResponseResult"
                  :deep="10"
                  :highlightMouseoverNode="true"
                  :highlightSelectedNode="true"
                >
                </vue-json-pretty>
                <javascript-evaluate-result-test-output
                  v-else
                  :display-result="displayResult"
                />
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{
                  __("Cancel")
                }}</el-button>
              </span>
            </el-dialog>
            <el-dialog
              :title="__('Generated Code Completions')"
              :visible.sync="generatedCodeDialogVisible"
              width="50%"
              append-to-body
            >
              <div v-if="generateCodeCompletionResponse" id="codeCompletions">
                <div
                  v-for="(completion, key) in this
                    .generateCodeCompletionResponse"
                  :key="key"
                >
                  <ace-editor
                    height="200px"
                    v-model="completion.text"
                    lang="javascript"
                    theme="solarized_dark"
                    width="100%"
                  ></ace-editor>
                  <el-row>
                    <el-col
                      class="py-2"
                      style="display: flex; justify-content: flex-end; align-items: center"
                    >
                      <el-button @click="useCompletion(completion.text)">
                        {{ __("Use") }}
                      </el-button>
                    </el-col>
                  </el-row>
                </div>
              </div>

              <span slot="footer" class="dialog-footer">
                <el-button @click="closeCodeCompletionDialog">{{
                  __("Cancel")
                }}</el-button>
              </span>
            </el-dialog>
          </el-col>
          <el-col
            style="display: flex; justify-content: flex-end; align-items: center"
          >
            <el-button
              :disabled="isTestJavascriptFunctionButtonDisabled"
              @click="evaluateJavascriptFunction"
              id="testJavascriptFunctionButton"
            >
              {{ __("Test") }}
            </el-button>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from "lodash";
import SupportedLibraries from "./LibraryList";
import { evaluateJavascriptFunction } from "@/api/services";
import VueJsonPretty from "vue-json-pretty";
import JSTypesValidator from "@/utils/JsContentFnTypeValidator";
import { maxCodeLimitCharacters } from "@/constants/javscriptFunctions";
import PageHeader from "@/components/PageHeader";
import { generateCodeCompletions } from "@/api/javascript";
import WandIcon from "@/components/WandIcon.vue";

const AceEditor = () =>
  import(/* webpackChunkName: "aceEditor" */ "./aceEditor");

const FunctionArgumentTable = () =>
  import(
    /* webpackChunkName: "functionArgumentTable" */ "./FunctionArgumentTable"
  );

const JavascriptTypeSelector = () =>
  import(/* webpackChunkName: "javascriptTypeSelector" */ "./TypeSelector");

const JavascriptIdentifierInput = () =>
  import(
    /* webpackChunkName: "javascriptIdentifierInput" */ "./JavascriptIdentifierInput"
  );

const JavascriptEvaluateResultTestOutput = () =>
  import(
    /* webpackChunkName: "javascriptEvaluateResultTestOutput" */ "@/views/build/content/javascripts/components/JavascriptEvaluateResultTestOutput"
  );

const ARG_LIMIT = 4;
const ERROR_TYPE_TEST_VALUE = "test_value_error";

const emptyArg = () => ({
  name: "",
  parameter_type: "",
  description: "",
  parameter_default_value: "",
  parameter_test_value: ""
});

const insertToken = "[insert]";

export default {
  components: {
    WandIcon,
    JavascriptEvaluateResultTestOutput,
    PageHeader,
    AceEditor,
    JavascriptTypeSelector,
    JavascriptIdentifierInput,
    FunctionArgumentTable,
    VueJsonPretty
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    generateCodeEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      ARG_LIMIT,
      enablePreviewButton: false,
      javascriptFn: {},
      javascriptType: [
        {
          label: __("Select Type"),
          value: "",
          disabled: true
        },
        {
          label: __("Hello"),
          value: "There"
        }
      ],
      argumentsCopy: null,
      newArg: emptyArg(),

      SupportedLibraries,
      requestingHttpResponse: false,
      displayEditor: false,
      evaluateJavascriptFunctionHttpResponse: null,
      dialogVisible: false,
      generatedCodeDialogVisible: false,
      generateCodeCompletionResponse: null,
      noCodeCompletionsReturns: false,
      generatingCodeCompletions: false,
      maxCodeLimitCharacters: maxCodeLimitCharacters
    };
  },
  computed: {
    _() {
      return _;
    },

    isCreatingNewJavascript() {
      return !_.has(this.javascriptFn, "javascript_function_id");
    },

    functionArgumentErrorDesc() {
      // eslint-disable-next-line
      return __('You have an invalid test value in your arguments list. Test your function before saving to avoid issues in your task.');
    },

    /**
     * A computed property to get the title for the page with the current JS object state
     * @returns {string}
     */
    headerTitle() {
      const title = __("JavaScript Function");
      return this.isCreatingNewJavascript
        ? __("Create :title", { title: title })
        : __("Update :title", { title: title });
    },

    /**
     * A computed property to get the name of all of the function arguments
     * @returns {string[]}
     */
    getFnArgsName() {
      let fnArgs = [];

      for (
        let i = 0;
        i < Math.min(this.javascriptFunctionParameters.length, ARG_LIMIT);
        ++i
      ) {
        if (!_.isEmpty(this.javascriptFunctionParameters[i].name))
          fnArgs.push(this.javascriptFunctionParameters[i].name);
      }

      return fnArgs;
    },
    /**
     * get the selected library links
     * @returns {unknown[]}
     */
    selectedLibraryLinks() {
      return _.filter(this.SupportedLibraries, item => {
        return this.javascriptFn.libraries.includes(item.value);
      });
    },

    /**
     *  js function has libraries
     * @returns {boolean}
     */
    hasLibraries() {
      return (
        Array.isArray(this.javascriptFn.libraries) &&
        this.javascriptFn.libraries.length > 0
      );
    },

    /**
     * title of the response error
     * @returns {string}
     */
    getResponseTitle() {
      if (this.evaluateJavascriptFunctionHttpResponse) {
        return __("Javascript function evaluation :status", {
          status:
            this.getResponseStatus === "success"
              ? __("successful")
              : __("failed")
        });
      }

      return "";
    },
    /**
     * Check response success
     * @returns {string}
     */
    getResponseStatus() {
      if (this.evaluateJavascriptFunctionHttpResponse) {
        if (
          this.evaluateJavascriptFunctionHttpResponse.status === 200 &&
          !this.evaluateJavascriptFunctionHttpResponse.data.errors
        ) {
          return "success";
        }
      }

      return "error";
    },

    /**
     * Check for displaying in vue json pretty
     *
     * @returns {boolean}
     */
    isOutputJson: function() {
      // handle the 200 result having html escaped chars scenario, can't pass this to json pretty because outputs as standard html
      if (
        this.evaluateJavascriptFunctionHttpResponse &&
        this.evaluateJavascriptFunctionHttpResponse.status === 200 &&
        this.evaluateJavascriptFunctionHttpResponse.data.result &&
        typeof this.evaluateJavascriptFunctionHttpResponse.data.result !==
          "object" &&
        !Array.isArray(this.evaluateJavascriptFunctionHttpResponse.data.result)
      ) {
        return false;
      }

      return true;
    },

    /**
     * display result in js evaluate result test output
     * @returns {string}
     */
    displayResult: function() {
      // Convert preview result to string
      if (!this.isOutputJson) {
        return String(this.evaluateJavascriptFunctionHttpResponse.data.result);
      }

      return "";
    },
    /**
     * get the response result
     * @returns {{}|*}
     */
    getResponseResult() {
      if (this.evaluateJavascriptFunctionHttpResponse) {
        if (this.evaluateJavascriptFunctionHttpResponse.status === 200) {
          return this.evaluateJavascriptFunctionHttpResponse.data;
        } else if (
          this.evaluateJavascriptFunctionHttpResponse.response.status === 422
        ) {
          return this.evaluateJavascriptFunctionHttpResponse.response.data;
        } else {
          return { errors: [__("Server Error, please contact provider")] };
        }
      }

      return "";
    },
    /**
     * check size of javascript function content
     * @returns {boolean}
     */
    isMaxCodeLimitExceeded() {
      return (
        this.javascriptFunctionContent.length > this.maxCodeLimitCharacters
      );
    },

    /**
     * check test button is disabled
     * @returns {boolean}
     */
    isTestJavascriptFunctionButtonDisabled() {
      return this.requestingHttpResponse || this.isMaxCodeLimitExceeded;
    },

    /**
     * getter/setter for function content
     */
    javascriptFunctionContent: {
      get() {
        return this.javascriptFn.function_content || "";
      },
      set(val) {
        this.javascriptFn.function_content = val;
      }
    },

    /**
     * getter/setter for function parameters
     */
    javascriptFunctionParameters: {
      get() {
        return this.javascriptFn.javascript_function_parameters || [];
      },
      set(val) {
        this.javascriptFn.javascript_function_parameters = val;
      }
    },

    /**
     * Get all error messages for the function arguments
     * @returns { Array.<Object> } Array of objects containing the keys: argName, errorType, argError
     */
    functionArgumentErrors() {
      return this.javascriptFunctionParameters.reduce((prev, functionArg) => {
        if (
          typeof JSTypesValidator[functionArg.parameter_type] === "function"
        ) {
          const argError = JSTypesValidator[functionArg.parameter_type](
            functionArg.parameter_test_value
          );

          if (functionArg.parameter_test_value !== "" && argError !== "") {
            prev.push({
              argName: functionArg.name,
              errorType: ERROR_TYPE_TEST_VALUE,
              argError
            });
          }
        }

        return prev;
      }, []);
    },

    /**
     * Check if the function arguments contain any error
     * @returns { Boolean } True if functionArgumentErrors is not empty
     */
    hasFunctionArgumentError() {
      return this.functionArgumentErrors.length > 0;
    }
  },
  methods: {
    functionArgumentChangeHandler() {
      this.insertEmptyArgument();
      let copyCount = this.argumentsCopy.length;

      if (copyCount - 1 > -1) {
        if (
          _.isEmpty(this.argumentsCopy[copyCount - 1].name) ||
          _.isEmpty(this.argumentsCopy[copyCount - 1].parameter_type)
        ) {
          --copyCount;
        }
      }
      this.javascriptFunctionParameters = _.cloneDeep(
        this.argumentsCopy.slice(0, copyCount)
      );
    },
    insertEmptyArgument() {
      const lastIdx = this.argumentsCopy.length - 1;

      if (this.argumentsCopy.length === 0) {
        this.argumentsCopy.push(emptyArg());
      } else {
        if (
          this.argumentsCopy.length < ARG_LIMIT &&
          !_.isEmpty(this.argumentsCopy[lastIdx].name) &&
          !_.isEmpty(this.argumentsCopy[lastIdx].parameter_type)
        ) {
          this.argumentsCopy.push(emptyArg());
        }
      }
    },

    /**
     * initialise javascript function object
     */
    initializeJavaScript() {
      this.javascriptFn = this.value;
      this.javascriptFn.is_test = true;
      this.argumentsCopy = _.cloneDeep(this.javascriptFunctionParameters);
      this.insertEmptyArgument();
    },

    onJavascriptFnTypeChange() {},
    onArgNameChange(event) {
      this.newArg.name = event;
      this.$emit("all-argument-saved", _.isEmpty(event));
    },

    editorInit(editor) {
      this.editor = editor;
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/html");
      require("brace/mode/javascript"); // language
      require("brace/mode/less");
      require("brace/theme/solarized_dark");
      require("brace/snippets/javascript"); //snippet
    },

    countSameArgName(name, index) {
      let sameNameCounter = 0;
      for (let i = 0; i < this.argumentsCopy.length; ++i) {
        if (i === index) continue;
        if (this.argumentsCopy[i].name === name) ++sameNameCounter;
      }

      return sameNameCounter;
    },
    updateArgName(event, index) {
      const sameNameCounter = this.countSameArgName(event, index);
      if (sameNameCounter !== 0) {
        this.$message({
          type: "error",
          // eslint-disable-next-line
          message: __("Failed to update function argument. Function argument :event already exists.", { event: event })
        });
        return;
      }

      this.argumentsCopy[index].name = event;
    },

    beautifyLibraryLabel(libObj) {
      return `${libObj.label} (${libObj.version})`;
    },

    /**
     * update the selected libraries on the content form
     */
    updateSelectedLibraries() {
      this.$emit("update-selected-libraries", this.javascriptFn.libraries);
    },

    generateCodeCompletion() {
      this.generatingCodeCompletions = true;
      this.generateCodeCompletionResponse = null;
      this.noCodeCompletionsReturns = false;

      let hasInsertIcon = this.editor.find(insertToken);

      if (hasInsertIcon === undefined) {
        this.editor.insert(insertToken);
      }

      generateCodeCompletions(this.javascriptFn)
        .then(response => {
          const choicesWithText = response.data.choices.filter(
            choice => choice.text
          );

          if (choicesWithText.length === 0) {
            this.noCodeCompletionsReturns = true;
            return;
          }

          this.generateCodeCompletionResponse = choicesWithText;
          this.generatedCodeDialogVisible = true;
        })
        .catch(error => {
          this.$message({
            type: "error",
            message: error.message
          });
        })
        .finally(() => (this.generatingCodeCompletions = false));
    },

    closeCodeCompletionDialog() {
      this.editor.find(insertToken);
      this.editor.replace("");
      this.generatedCodeDialogVisible = false;
    },

    /**
     * evaluate the js function with a call to the studio js function server
     */
    evaluateJavascriptFunction() {
      this.resetEvaluateResponse();
      if (this.javascriptFn) {
        this.requestingHttpResponse = true;

        let self = this;
        setTimeout(() => {
          evaluateJavascriptFunction(self.javascriptFn)
            .then(result => {
              self.evaluateJavascriptFunctionHttpResponse = result;
              self.requestingHttpResponse = false;
              self.dialogVisible = true;
            })
            .catch(errors => {
              self.evaluateJavascriptFunctionHttpResponse = errors;
              self.requestingHttpResponse = false;
              self.dialogVisible = true;
            });
        }, 300);
      } else {
        this.resetEvaluateResponse();
        this.requestingHttpResponse = false;
        this.dialogVisible = false;
      }
    },

    /**
     * on close event, reset response
     */
    resetEvaluateResponse() {
      this.evaluateJavascriptFunctionHttpResponse = null;
    },

    formatArgumentError(error) {
      const translateVar = { argName: error.argName, message: error.argError };

      // Use if statement in case we need to add further validations in the future
      if (error.errorType === ERROR_TYPE_TEST_VALUE) {
        // eslint-disable-next-line
        return __('Error in argument ":argName" test value: :message', translateVar)
      }

      return "";
    },

    useCompletion(text) {
      this.editor.find(insertToken);
      this.editor.replace(text);

      this.generatedCodeDialogVisible = false;
    }
  },

  watch: {
    value: {
      immediate: true,
      handler: "initializeJavaScript"
    }
  }
};
</script>

<style lang="scss" scoped>
.form-container {
  min-width: 450px;
}

.form-test-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
}

.form-editor-container {
  padding: 0 20px;
  background: #262831;
  border-radius: 4px;
  margin-bottom: 15px;
}

.ace_editor_dummy-text {
  padding: 10px 0;
  background: transparent;
}

.form-editor-container-functionArgRow {
  margin: 15px;
}

.form-editor-container-functionArgRow:last-of-type {
  margin-bottom: 0;
}

.form-editor-container-functionArgRow .actionCol > div {
  display: none;
}

.form-editor-container-functionArgRow:hover .actionCol > div {
  display: block;
}

#functionArgumentErrorDesc {
  word-break: normal;
}
</style>
