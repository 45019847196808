<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.9703 6.24234L17.556 4.82812L14.0205 8.36366L15.4347 9.77787L18.9703 6.24234ZM14.0205 11.1921L12.6063 9.77787L4.82812 17.556L6.24234 18.9703L14.0205 11.1921ZM20.3845 7.65655C21.1655 6.8755 21.1655 5.60917 20.3845 4.82812L18.9703 3.41391C18.1892 2.63286 16.9229 2.63286 16.1418 3.41391L12.6063 6.94945L3.41391 16.1418C2.63286 16.9229 2.63286 18.1892 3.41391 18.9703L4.82812 20.3845C5.60917 21.1655 6.8755 21.1655 7.65655 20.3845L16.8478 11.1933C16.8482 11.1929 16.8485 11.1925 16.8489 11.1921L20.3845 7.65655Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5 1C7.94772 1 7.5 1.44772 7.5 2V3.5H6C5.44772 3.5 5 3.94772 5 4.5C5 5.05228 5.44772 5.5 6 5.5H7.5V7C7.5 7.55228 7.94772 8 8.5 8C9.05229 8 9.5 7.55228 9.5 7V5.5H11C11.5523 5.5 12 5.05228 12 4.5C12 3.94772 11.5523 3.5 11 3.5H9.5V2C9.5 1.44772 9.05229 1 8.5 1Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.4999 12C20.1054 12 19.7856 12.3198 19.7856 12.7143V13.7853H18.7143C18.3198 13.7853 18 14.1051 18 14.4996C18 14.8941 18.3198 15.2139 18.7143 15.2139H19.7856V16.2857C19.7856 16.6802 20.1054 17 20.4999 17C20.8944 17 21.2142 16.6802 21.2142 16.2857V15.2139H22.2857C22.6802 15.2139 23 14.8941 23 14.4996C23 14.1051 22.6802 13.7853 22.2857 13.7853H21.2142V12.7143C21.2142 12.3198 20.8944 12 20.4999 12Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.00014 8C4.68455 8 4.42871 8.25584 4.42871 8.57143V9.42843H3.57143C3.25584 9.42843 3 9.68427 3 9.99986C3 10.3155 3.25584 10.5713 3.57143 10.5713H4.42871V11.4286C4.42871 11.7442 4.68455 12 5.00014 12C5.31573 12 5.57157 11.7442 5.57157 11.4286V10.5713H6.42857C6.74416 10.5713 7 10.3155 7 9.99986C7 9.68427 6.74416 9.42843 6.42857 9.42843H5.57157V8.57143C5.57157 8.25584 5.31573 8 5.00014 8Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: "WandIcon"
};
</script>
