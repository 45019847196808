import JSTypes from "@/utils/JsContentFnTypeHelper";
import moment from "moment-timezone";

/**
 * String validator
 * @param { String } input
 * @returns
 */
function stringValidator() {
  return "";
}

/**
 * String validator
 * @param { String } input
 * @returns { String } Error message or empty string if no error
 */
function booleanValidator(input) {
  if (["true", "false"].indexOf(input) === -1) {
    // eslint-disable-next-line
    return __('Value is neither "true" or "false"')
  }

  return "";
}

/**
 * KVList validator
 * @param { String } input
 * @returns { String } Error message or empty string if no error
 */
function kvlistValidator(input) {
  // Validate KVList using the built-in JSON parser
  try {
    JSON.parse(input);
  } catch (e) {
    // eslint-disable-next-line
    return __('Invalid JSON format');
  }

  return "";
}

/**
 * Numeric validator
 * @param { String } input
 * @returns { String } Error message or empty string if no error
 */
function numericValidator(input) {
  // use type coercion to parse the _entirety_ of the string
  // (`parseFloat` alone does not do this) and ensure strings of whitespace fail
  if (isNaN(input) || isNaN(parseFloat(input))) {
    // eslint-disable-next-line
    return __('Not a valid number');
  }

  return "";
}

/**
 * Date validator
 * @param { String } input
 * @returns { String } Error message or empty string if no error
 */
function dateValidator(input) {
  const formatsCarbon = [
    "YY-MM-DD",
    "DD.MM.YY",
    "MM/DD/YY",
    "YYMMDD",
    "YYYY-MM-DD"
  ];
  const formatsPhp = ["y-m-d", "d.m.y", "m/d/y", "ymd", "Y-m-d"];

  for (let i = 0; i < formatsCarbon.length; ++i) {
    const format = formatsCarbon[i];

    // Make sure that we have all separators
    // Return true on first valid date
    if (moment(input, format, true).isValid()) {
      return "";
    }
  }

  // eslint-disable-next-line
  return __('Invalid date format. Supported formats are :supportedFormats', {
    supportedFormats: formatsPhp.join(", ")
  });
}

/**
 * Check if time string contains color
 * @param {string} time The time string to check
 */
function timeStringContainsColon(time) {
  return time.split(":").length === 2;
}

/**
 * Time validator
 * @param { String } input
 * @returns { String } Error message or empty string if no error
 */
function timeValidator(input) {
  const formatsCarbon = ["HHmm", "HHmmZZ"];
  const formatsPhp = ["Hi", "HiP"];

  for (let i = 0; i < formatsCarbon.length; ++i) {
    // Special case for HiP, colon is required. Continue if not found
    if (formatsCarbon[i] === "HHmmZZ" && !timeStringContainsColon(input)) {
      continue;
    }

    // Return true on first valid time
    if (moment(input, formatsCarbon[i], true).isValid()) {
      return "";
    }
  }

  // eslint-disable-next-line
  return __('Invalid time format. Supported formats are :supportedFormats. Example: 1245, 0000+02:30, 1230:-10:00', {
      supportedFormats: formatsPhp.join(", ")
    }
  );
}

/**
 * Generic currency validator
 * @param { String } input
 * @link https://www.php.net/manual/en/numberformatter.formatcurrency.php In the JS server, the currency is validated using PHP formatCurrency. The function takes a float for the amount
 * @returns { String } Error message or empty string if no error
 */
function currencyValidator(input) {
  return numericValidator(input);
}

/**
 * Map type to validator
 * @type { [x: number]: (input: string) => string }
 */
const validator = {
  [JSTypes.TYPE_STRING]: stringValidator,
  [JSTypes.TYPE_BOOLEAN]: booleanValidator,
  [JSTypes.TYPE_NUMERIC]: numericValidator,

  [JSTypes.TYPE_CURRENCY_USD]: currencyValidator,
  [JSTypes.TYPE_CURRENCY_GPB]: currencyValidator,
  [JSTypes.TYPE_CURRENCY_EUR]: currencyValidator,

  [JSTypes.TYPE_DATE]: dateValidator,
  [JSTypes.TYPE_TIME]: timeValidator,

  [JSTypes.TYPE_KVLIST]: kvlistValidator
};

export default validator;
