function libraryFactory(label, value, version, docLink = "") {
  let ret = {};
  ret[value] = {
    label,
    value,
    version,
    docLink
  };
  return ret;
}

export default {
  ...libraryFactory(
    "Lodash",
    "lodash",
    "4.17.21",
    "https://lodash.com/docs/4.17.15"
  ),
  ...libraryFactory(
    "Moment",
    "moment",
    "2.29.1",
    "https://momentjs.com/docs/#/parsing/"
  ),
  ...libraryFactory(
    "Underscore",
    "underscore",
    "1.13.2",
    "https://underscorejs.org/#collections"
  ),
  ...libraryFactory(
    "CryptoJS",
    "crypto-js",
    "4.1.1",
    "https://cryptojs.gitbook.io/docs/"
  ),
  ...libraryFactory(
    "date-fns",
    "date-fns",
    "2.29.3",
    "https://date-fns.org/v2.29.3/docs/Getting-Started"
  ),
  ...libraryFactory("joi", "joi", "17.7.0", "https://joi.dev/api/?v=17.7.0"),
  ...libraryFactory("Zod", "zod", "3.19.1", "https://zod.dev/"),
  ...libraryFactory(
    "Camaro",
    "camaro",
    "6.2.0",
    "https://github.com/tuananh/camaro"
  ),
  ...libraryFactory(
    "JsonWebToken",
    "jsonwebtoken",
    "9.0.0",
    "https://github.com/auth0/node-jsonwebtoken"
  )
};
