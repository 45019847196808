<template>
  <div class="content-component" @click="handleClearSelection">
    <el-button
      :disabled="!can('content.' + additionalValidateRoute + '.write')"
      class="createBtn"
      @click.stop="createContentItem"
      >{{ __("Create Function") }}</el-button
    >
    <div style="width: 100%">
      <el-main style="margin-top: 136px;">
        <el-form class="form-container">
          <PaginationToolbar
            :sort-by-options="sortByOptions"
            :content-api="contentAPI"
            :show-content-view-switcher="true"
            @success="$emit('replaceContent', $event)"
          >
          </PaginationToolbar>
        </el-form>
        <el-scrollbar v-loading="loading" :native="true">
          <div class="contentCardsContainer">
            <el-row
              :class="contentViewClasses"
              v-if="javascripts.length"
              v-show="can('content.' + additionalValidateRoute + '.read')"
            >
              <content-list-item
                :content-view-type="contentViewType"
                v-for="(javascript, index) in javascriptsForTableView"
                :item-index="index"
                :key="'_content_javascripts_' + index"
                :draggable-data="{
                  content_id: selectedItems.length
                    ? selectedIds
                    : [javascript[primaryKey]],
                  content_type: contentType
                }"
                :allow-multi-select="true"
                :card-class="{ selected: selectedItemIndex === index }"
                icon="icon-variable.svg"
                :card-text="javascript.name"
                @delete="
                  handleDelete(
                    javascript,
                    'JavascriptFunction',
                    javascript.javascript_function_id
                  )
                "
                :content-list-item="javascript"
                :fields-to-display="displayFields"
                @select="handleSelect(index, javascript)"
                @edit="handleEdit(index, javascript)"
                @checked-content="handleSelectMultiple($event)(javascript)"
                @toggle-select-all="
                  handleToggleSelectAll(javascripts, ...arguments)
                "
                :is-all-selected="isAllSelected"
                :total-items-selected="selectedItems.length"
              ></content-list-item>
            </el-row>
            <el-row
              v-else-if="!loading"
              style="padding-top: 100px;"
              type="flex"
              justify="center"
            >
              {{ __("No JavaScript") }}
            </el-row>
          </div>
        </el-scrollbar>
      </el-main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import ContentListItem from "@/views/build/content/mixins/ContentListItem";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import _ from "lodash";

export default {
  name: "JavascriptsList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    ContentListItem
  },
  data() {
    return {
      additionalValidateRoute: "javascript-functions",
      sortByColumns: ["name", "javascript_function_id"],
      isContent: true,
      viewMode: "allow_read",
      displayFields: [
        {
          field_name: "name",
          display_name: __("Name")
        },
        {
          field_name: "description",
          display_name: __("Description")
        },
        {
          field_name: "function_return_type",
          display_name: __("Return Type")
        },
        {
          field_name: "javascript_arguments",
          display_name: __("Arguments")
        }
      ]
    };
  },

  computed: {
    ...mapState("javascripts", {
      javascripts: state => state.javascripts,
      loading: state => state.loading
    }),

    selectedIds() {
      return _.map(this.selectedItems, this.primaryKey);
    },

    javascriptsForTableView() {
      let javascriptsForTableView = this.javascripts;
      if (this.javascripts) {
        javascriptsForTableView = javascriptsForTableView.reduce(
          (prev, curr) => {
            curr["javascript_arguments"] = curr[
              "javascript_function_parameters"
            ]
              .map(arg => arg.name)
              .join(", ");
            prev.push(curr);
            return prev;
          },
          []
        );
      }
      return javascriptsForTableView;
    }
  },

  methods: {
    ...mapActions("javascripts", {
      contentAPI: "getJavascriptFunctionsForList"
    }),
    handleCommand(command) {
      this.createContentItem({ variable_type: command });
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-card.scss";
@import "~@/styles/pagination-toolbar.scss";
</style>
