<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState, mapActions } from "vuex";
export default {
  name: "JavascriptsIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },
  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("javascripts", {
      contents: state => state.javascripts
    })
  },

  methods: {
    ...mapActions("javascripts", {
      resetJavascripts: "resetJavascript"
    }),
    initContent() {
      return {
        name: "",
        function_return_type: "",
        description: "",
        function_content: "",
        javascript_function_parameters: [],
        libraries: []
      };
    }
  },

  created() {
    this.resetJavascripts();
  },

  mounted() {
    this.contentType = "JavascriptFunction";
    this.primaryKey = "javascript_function_id";
  }
};
</script>

<style lang="scss" scoped></style>
