/**
 * This file has to be in sync with App\Http\Controllers\Api\Util\SystemVariable.php file in backend
 *
 * Just the system variable names. No need to bring the ids or task type mapping from backend
 */

const PREFIX = "sys";
const SEPARATOR = ".";
const SYS_PREFIX = PREFIX + SEPARATOR;

export const SYS_VAR = {
  PREFIX,
  SEPARATOR
};

export const SYSTEM_VARIABLE = Object.freeze({
  ANI: SYS_PREFIX + "ANI",
  DNIS: SYS_PREFIX + "DNIS",
  UUID: SYS_PREFIX + "UUID",
  MESSAGE_TEXT: SYS_PREFIX + "MESSAGE_TEXT",
  MESSAGE_JSON: SYS_PREFIX + "MESSAGE_JSON",
  // for now, we only have MESSAGE_TEXT system variable
  // LAST_MESSAGE: SYS_PREFIX + "LAST_MESSAGE",
  CALL_START_TIME: SYS_PREFIX + "CALL_START_TIME",
  REGION: SYS_PREFIX + "REGION",
  POP: SYS_PREFIX + "POP",
  SP_NAME: SYS_PREFIX + "ServiceProviderName",
  SP_ID: SYS_PREFIX + "ServiceProviderId",
  AC_NAME: SYS_PREFIX + "AccountName",
  AC_ID: SYS_PREFIX + "AccountId",
  TASK_NAME: SYS_PREFIX + "TaskName",
  TASK_ID: SYS_PREFIX + "TaskId",
  CURRENT_TASK_ID: SYS_PREFIX + "CurrentTaskId",
  CURRENT_TASK_NAME: SYS_PREFIX + "CurrentTaskName",
  TASK_TYPE: SYS_PREFIX + "TypeDetected",
  LAST_MESSAGE_MEDIA_TYPE: SYS_PREFIX + "LAST_MESSAGE_MEDIA_TYPE",
  LAST_MESSAGE_MEDIA: SYS_PREFIX + "LAST_MESSAGE_MEDIA",
  LOCATION_LATITUDE: SYS_PREFIX + "LocationLatitude",
  LOCATION_LONGITUDE: SYS_PREFIX + "LocationLongitude",
  DOMAIN_ID: SYS_PREFIX + "DOMAIN_ID"
});
